// EmailForm.js

import * as React from 'react';
import CustomButton from './components/CustomButton';
import Fade from '@mui/material/Fade';
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Chip,
  Box,
  Typography,
  Snackbar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InstructionPanel from './components/InstructionPanel';
import LoadingScreen from './components/LoadingScreen';
import ReconsentForm from './components/ReconsentForm';
import deployment_config from './deployment_config.json';
import { Helmet } from 'react-helmet';
import FAQ from './components/faq';
import AnnouncementModal from './components/AnnouncementModal';

import {
  storeUserEmail,
  getUserEmail,
  storeFriendEmails,
  getFriendEmails,
} from './utils';

export default function EmailForm({
  email,
  setEmail,
  setAge,
  setCurrentForm,
  reconsent,
  setReconsent,
  selectedGameMode,
}) {
  const [emailError, setEmailError] = React.useState(false);
  const [friendEmailInput, setFriendEmailInput] = React.useState('');
  const [friendEmails, setFriendEmails] = React.useState([]);
  const [friendEmailError, setFriendEmailError] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [showConsentForm, setShowConsentForm] = React.useState(false);
  const apiBaseUrl = deployment_config.apiBaseUrl;

  // New state to handle if the user is banned
  const [isBanned, setIsBanned] = React.useState(false);

  // Snackbar state for visual feedback
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [announcementSnackbar, setAnnouncementSnackbar] = React.useState({
    open: false,
    message: '',
  });

  // State for Announcement Modal
  const [showAnnouncement, setShowAnnouncement] = React.useState(true);

  // State to control InstructionPanel's auto-open and video autoplay
  const [instructionOpen, setInstructionOpen] = React.useState(false);

  // Fetch the decrypted email and friend emails when the component mounts
  React.useEffect(() => {
    async function fetchEmails() {
      const storedUserEmail = await getUserEmail();
      const storedFriendEmails = await getFriendEmails();

      if (storedUserEmail) {
        setEmail(storedUserEmail.toLowerCase()); // Ensure lowercase
      }
      if (storedFriendEmails && storedFriendEmails.length > 0) {
        // Ensure all friend emails are lowercase
        const lowercasedFriendEmails = storedFriendEmails.map(email => email.toLowerCase());
        setFriendEmails(lowercasedFriendEmails);
      }
    }
    fetchEmails();
  }, [setEmail]);

  // Re-validate friend emails when main email changes
  React.useEffect(() => {
    if (email) {
      // Filter out any friend emails that now match the new user email
      const filteredFriendEmails = friendEmails.filter(
        (friendEmail) => friendEmail !== email
      );
      if (filteredFriendEmails.length !== friendEmails.length) {
        setFriendEmails(filteredFriendEmails);
        setAnnouncementSnackbar({
          open: true,
          message:
            "Some friend emails were removed because they matched your new email.",
        });
      }

      // Additionally, re-validate the current friendEmailInput
      const error = validateFriendEmail(friendEmailInput);
      setFriendEmailError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const clear = () => {
    setEmail('');
    setFriendEmailInput('');
    setFriendEmails([]);
    setAge(null);
  };

  // **Updated handleEmailChange to normalize email to lowercase**
  const handleEmailChange = (e) => {
    const value = e.target.value.toLowerCase(); // Convert to lowercase
    setEmail(value);

    const isValidEmail = validateEmail(value);
    setEmailError(!isValidEmail);
  };

  // Updated validation function
  const validateFriendEmail = (value) => {
    if (value.trim() === '') {
      return ''; // No error for empty input
    }
    if (!validateEmail(value)) {
      return 'Invalid email address';
    }
    if (value === email) {
      return "You can't invite yourself!";
    }
    if (friendEmails.includes(value)) {
      return 'Email already added';
    }
    return '';
  };

  // **Updated handleFriendEmailInputChange to normalize friend email to lowercase**
  const handleFriendEmailInputChange = (e) => {
    const value = e.target.value.toLowerCase(); // Convert to lowercase
    setFriendEmailInput(value);

    const error = validateFriendEmail(value);
    setFriendEmailError(error);
  };

  const handleAddFriendEmail = () => {
    if (friendEmailInput) {
      const error = validateFriendEmail(friendEmailInput);
      if (error) {
        setFriendEmailError(error);
      } else {
        setFriendEmails([...friendEmails, friendEmailInput]);
        setFriendEmailInput(''); // Clear input field
        setSnackbarOpen(true); // Show feedback
        setFriendEmailError('');
      }
    }
  };

  const handleDeleteFriendEmail = (emailToDelete) => {
    setFriendEmails((emails) => emails.filter((email) => email !== emailToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (friendEmailInput && !friendEmailError) {
        handleAddFriendEmail(); // Add friend email automatically
      }

      if (
        email &&
        !emailError &&
        !friendEmailError &&
        (selectedGameMode !== 'collaborative' || friendEmails.length > 0)
      ) {
        setLoading(true);
        submitEmail();
      }
    }
  };

  const validateEmail = (value) => {
    const emailRegex =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const submitEmail = () => {
    // Create a local array to ensure we include the latest friend emails
    let updatedFriendEmails = [...friendEmails];

    if (friendEmailInput) {
      const error = validateFriendEmail(friendEmailInput);
      if (error) {
        setFriendEmailError(error);
        setLoading(false); // Stop loading
        return; // Exit the function
      } else {
        updatedFriendEmails.push(friendEmailInput.toLowerCase()); // Ensure lowercase
        setFriendEmails(updatedFriendEmails);
        setFriendEmailInput(''); // Clear input field
        setSnackbarOpen(true); // Show feedback
        setFriendEmailError('');
      }
    }

    storeUserEmail(email.toLowerCase()); // Ensure stored email is lowercase
    storeFriendEmails(updatedFriendEmails.map(email => email.toLowerCase())); // Ensure stored friend emails are lowercase

    const jsonObject = {
      email: email.toLowerCase(), // Ensure lowercase
      friendEmails: updatedFriendEmails.map(email => email.toLowerCase()), // Ensure lowercase
      reconsent: reconsent,
      gameMode: selectedGameMode, // Include game mode in the request
    };

    console.log('reconsent: ' + reconsent);

    fetch(apiBaseUrl + '/send-token-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonObject),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 303) {
          response.json().then((data) => {
            setCurrentForm('user');
          });
        } else if (response.status === 307) {
          alert(
            'Maintenance is ongoing, please come back later. See discord for status update!'
          );
          throw new Error(
            'Server in maintenance, please come back later. See discord for status update!'
          );
        } else if (response.status === 308) {
          alert(
            'All Minecraft licenses are occupied, please come back later!'
          );
          throw new Error(
            'All Minecraft licenses are occupied, please come back later!'
          );
        } else if (response.status === 305) {
          throw new Error('ConsentRequired');
        } else if (response.status === 304) {
          alert(
            "PLAICraft thinks you're still playing on the account with this email, probably because you just disconnected or you recently quit and are trying to rejoin. Our data gets messed up if you use the same email in two simultaneous sessions and it takes us some time to clean up after disconnects and quits. Please try a different email or wait a few minutes and try again."
          );
          throw new Error();
        } else if (response.status === 403) {
          response.json().then((data) => {
            alert(`You are banned: ${data.ban_reason}`);
            setIsBanned(true); // Set the banned state to true
          });
          throw new Error('UserBanned');
        } else {
          response.json().then((data) => {
            let msg = JSON.stringify(data);
            if (msg.includes('mail')) {
              alert(
                'Something went wrong with your email, please check to make sure your email was entered correctly.'
              );
            } else if (msg.includes('Error')) {
              alert(msg);
            } else {
              alert(
                'Something went wrong... please report this and describe what you were doing when this error occurred.'
              );
            }
          });
          throw new Error();
        }
      })
      .then((data) => {
        setCurrentForm('token');
      })
      .catch((error) => {
        if (error.message === 'ConsentRequired') {
          setLoading(false);
          setShowConsentForm(true);
        } else if (error.message === 'UserBanned') {
          setLoading(false);
          // Additional handling if needed
        } else {
          console.error('Error:', error);
          setLoading(false);
        }
      });
  };

  // Handle reconsent logic
  React.useEffect(() => {
    if (reconsent && !showConsentForm) {
      submitEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconsent]);

  // Determine if inviting friends is required
  const isInviteFriendsRequired = selectedGameMode === 'collaborative';

  // Instruction text based on game mode
  const instructions = (
    <ol>
      <li>
        Enter your email to get a token to play (Why do we need your email? See the <FAQ />). You
        need a new token every time you play.
      </li>
      <li>
        {isInviteFriendsRequired
          ? 'You need to invite at least one friend to proceed.'
          : 'To play with friends, invite them by email here. They will get an email inviting them to join PLAICraft. They will be teleported to you in game.'}
      </li>
    </ol>
  );

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
    setInstructionOpen(true); // Allow InstructionPanel to expand and video to autoplay
  };

  // Construct iframe with autoplay based on instructionOpen
  const videoIframe = (
    <iframe
      width="100%"
      height="266"
      src={`https://www.youtube.com/embed/PdIjQAhQ3Xk?autoplay=${
        instructionOpen ? '1' : '0'
      }`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      allowFullScreen
    ></iframe>
  );

  return (
    <>
      <Helmet>
        <title>Free Minecraft For AI Research</title>
        <meta
          name="description"
          content="Enter your email to get free access to Minecraft and join the community."
        />
      </Helmet>
      {isLoading ? (
        <Fade in={isLoading} timeout={300}>
          <div>
            <LoadingScreen />
          </div>
        </Fade>
      ) : (
        <>
          {/* Show AnnouncementModal if showAnnouncement is true */}
          {showAnnouncement && (
            <AnnouncementModal
              open={showAnnouncement}
              onClose={handleCloseAnnouncement} // Update the onClose to handle opening InstructionPanel
            />
          )}
          {/* InstructionPanel is rendered regardless, but the video only autoplays if instructionOpen is true */}
          <form noValidate autoComplete="off" onKeyDown={handleKeyPress}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <InstructionPanel
                  instructions={instructions}
                  iframe={videoIframe}
                  autoOpen={instructionOpen} // Control auto-open
                />
              </Grid>
              <Grid item>
                <TextField
                  error={emailError}
                  helperText={emailError ? 'Invalid email address' : ''}
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  disabled={isBanned} // Disable input if banned
                />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="flex-start">
                  <TextField
                    error={!!friendEmailError}
                    helperText={friendEmailError}
                    label={`Friend Emails ${
                      isInviteFriendsRequired ? '' : '(optional)'
                    }`} // Conditional label
                    variant="outlined"
                    fullWidth
                    value={friendEmailInput}
                    onChange={handleFriendEmailInputChange}
                    disabled={isBanned} // Disable input if banned
                  />
                  <IconButton
                    color="primary"
                    onClick={handleAddFriendEmail}
                    disabled={
                      !friendEmailInput ||
                      !!friendEmailError ||
                      isBanned // Disable button if banned
                    }
                    aria-label="add friend email"
                    sx={{ mt: 1 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {friendEmails.length > 0 && (
                  <Box mt={2}>
                    <Typography variant="subtitle1">Friends you're inviting:</Typography>
                    <Box display="flex" flexWrap="wrap" mt={1}>
                      {friendEmails.map((email, index) => (
                        <Chip
                          key={index}
                          label={email}
                          onDelete={() => handleDeleteFriendEmail(email)}
                          deleteIcon={<DeleteIcon />}
                          sx={{ m: 0.5 }}
                          disabled={isBanned} // Disable chip if banned
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
              {/* Display error message if inviting friends is required but no friends are invited */}
              {isInviteFriendsRequired && friendEmails.length === 0 && (
                <Grid item>
                  <Typography variant="body2" color="error">
                    You must invite at least one friend to proceed.
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                spacing={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Grid item>
                  <CustomButton
                    isDisabled={
                      !email ||
                      emailError ||
                      (!!friendEmailError && friendEmailInput !== '') ||
                      (isInviteFriendsRequired && friendEmails.length === 0) ||
                      isBanned // Disable submit if banned
                    }
                    content="Submit"
                    color="success"
                    onClick={() => {
                      setLoading(true);
                      submitEmail();
                    }}
                  />
                </Grid>
                <Grid item ml={2}>
                  <CustomButton
                    isDisabled={isBanned} // Disable clear if banned
                    content="Clear"
                    color="primary"
                    onClick={clear}
                  />
                </Grid>
              </Grid>
              {showConsentForm && (
                <Grid item>
                  <ReconsentForm
                    consent={reconsent}
                    setConsent={(value) => {
                      setReconsent(value);
                      setShowConsentForm(false);
                      setLoading(true);
                    }}
                  />
                  {!reconsent && (
                    <Button color="error">
                      Consent terms have been updated! Approval is required to proceed.
                    </Button>
                  )}
                  {reconsent && <Button color="success">Consent Granted</Button>}
                </Grid>
              )}
            </Grid>
          </form>
        </>
      )}
      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message="Friend email added!"
      />
      {/* Snackbar for Announcement Modal notifications */}
      <Snackbar
        open={announcementSnackbar.open}
        autoHideDuration={6000}
        onClose={() => setAnnouncementSnackbar({ open: false, message: '' })}
        message={announcementSnackbar.message}
      />
    </>
  );
}
