import * as React from 'react';
import {
  Typography,
  Box,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import deployment_config from './deployment_config.json';
import { useTheme } from '@mui/material/styles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export default function ProgressBar({ instanceId, region, isRedirecting, setRedirecting }) {
  const [progress, setProgress] = React.useState(0);
  const [message, setMessage] = React.useState('Initializing playground... (approx. 5 minutes)');
  const [msgTimer, setMsgTimer] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const [counter, setCounter] = React.useState(0);
  const [readyForRedirect, setReadyForRedirect] = React.useState(false);
  const [instanceIP, setInstanceIP] = React.useState(null);
  const [instructionIndex, setInstructionIndex] = React.useState(0);
  const [showAllInstructions, setShowAllInstructions] = React.useState(false);
  const apiBaseUrl = deployment_config.apiBaseUrl;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const instructions = [
    {
      image: `${process.env.PUBLIC_URL}/icons/warning-triangle.png`,
      text: 'PLEASE READ AND FOLLOW THESE INSTRUCTIONS CAREFULLY. Failing to follow them could result in you being banned from further participation.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/time-left.png`,
      text: 'Be patient.  In about five minutes Minecraft will start in this browser window.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/microphone.png`,
      text: 'Make sure you have a mic and headphones.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/keep-quiet.png`,
      text: 'Play in a place free of background noises. Please only speak English. Remember that everything you said is recorded!',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/minecraft-loading.png`,
      text: 'If you are not in Minecraft at first, give it a few seconds to finish starting up.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/click.png`,
      text: 'You need to DOUBLE CLICK in the middle of this window before your mouse will work and you can play!',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/cancel.png`,
      text: 'Remember, do NOT type CTRL-W or refresh your page while playing.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/tpa-command.png`,
      text: 'Available commands are: /tpa for teleporting, /msg for private messages, /ITF for invisible item frames, /minigames to access the arena for minigames.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/afk.png`,
      text: 'Do NOT AFK in the game. You\'ll be kicked if AFK for more than a few minutes. If you get kicked more than three times, your account will be banned.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/warning-sheriff.png`,
      text: 'Be nice to each other, do NOT grief. We can detect it easily and we have backup every hour, so you will be wasting your time. Remember, EVERYTHING is recorded ;)',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/close-the-tab.png`,
      text: 'Once you are finished, please close your browser tab. You will need to long-press the Esc key to exit fullscreen.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/death-page.png`,
      text: 'If you die in the game, make sure you click Respawn. Do NOT return to the title screen or close the tab immediately. Your game state might not be saved.',
    },
    {
      image: `${process.env.PUBLIC_URL}/icons/discord.png`,
      text: 'If you have any questions or issues, please contact us on Discord.',
    },
  ];

  const get_ip_address = async (instance) => {
    const response = await fetch(apiBaseUrl + '/get-ec2-instance-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ instance_id: instance, region_name: region }),
    });
    const data = await response.json();
    setInstanceIP(data.state);
    return data.state;
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 99) {
          clearInterval(timer);
          return 99;
        }
        return Math.min(oldProgress + 3, 99);
      });

      get_ip_address(instanceId).then((state) => {
        if (['initializing'].includes(state) || !state || state === 'None' || state === 'null') {
          setCounter((oldCounter) => {
            if (oldCounter > 30 || ['impaired', 'insufficient-data', 'not-applicable'].includes(state)) {
              alert('Something went wrong. Please try again.');
              return oldCounter;
            }
            return oldCounter + 1;
          });
        } else {
          const audio = new Audio(process.env.PUBLIC_URL + '/ready.mp3');
          audio
            .play()
            .then(() => {
              setReadyForRedirect(true);
            })
            .catch((error) => {
              console.error('Error playing sound:', error);
              setReadyForRedirect(true);
            });
        }
      });
    }, 10000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceIP, apiBaseUrl]);

  React.useEffect(() => {
    if (readyForRedirect) {
      setRedirecting(true);
      setProgress(100);
      setMessage('Happy Mining!');
      clearInterval(msgTimer);
      setTimeout(() => {
        window.location.href = `/plaiground/index.html?ip=${instanceIP}`;
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyForRedirect, setRedirecting, instanceIP]);

  const handleOpen = () => {
    setOpen(true);
    setShowAllInstructions(true);
    setInstructionIndex(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (instructionIndex < instructions.length) {
      setInstructionIndex(instructionIndex + 1);
    }
  };

  const handleBack = () => {
    if (instructionIndex > 0) {
      setInstructionIndex(instructionIndex - 1);
    }
  };

  const handleFinish = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Typography
        variant="h6"
        gutterBottom
        color="white"
        textAlign="center"
        fontFamily="Minecraftia"
        sx={{ mb: 3 }}
      >
        {message} <br /> <br /> {progress}% <br /> <br /> DO NOT REFRESH THE PAGE
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '20px',
          bgcolor: '#5a5a5a',
          borderRadius: '10px',
          border: '2px solid #000000',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: `${progress}%`,
            bgcolor: '#71e849',
            transition: 'width .4s ease-in-out',
            position: 'relative',
            overflow: 'hidden',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
          <Link
            href="https://discord.gg/g6aSCqyEm8"
            target="_blank"
            underline="none"
            sx={{ display: 'flex', alignItems: 'center', p: 1 }}
          >
            <Typography sx={{ ml: 1 }} variant="body1" color="white" fontFamily="Minecraftia">
              Join our Discord:{' '}
            </Typography>
            <img
              src="https://img.icons8.com/?size=100&id=114902&format=png&color=000000"
              alt="Discord Icon"
              width="50"
            />
          </Link>
        </Box>
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{ position: 'absolute', right: -50, bottom: 150 }}
        >
          <InfoIcon />
        </IconButton>

        {/* Instruction Dialog */}
        <Dialog
          open={open}
          onClose={showAllInstructions ? handleClose : null}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: '15px',
              overflow: 'hidden',
            },
          }}
        >
          {showAllInstructions && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <DialogTitle id="dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            {showAllInstructions
              ? 'Instructions'
              : instructionIndex < instructions.length
              ? `Instruction ${instructionIndex + 1}`
              : 'Watch the Video'}
          </DialogTitle>
          <DialogContent dividers>
            {showAllInstructions ? (
              <>
                {/* Display all instructions without images */}
                <Typography id="dialog-description" sx={{ mt: 2 }}>
                  <ol>
                    {instructions.map((instr, idx) => (
                      <li key={idx}>{instr.text}</li>
                    ))}
                  </ol>
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube-nocookie.com/embed/4gRGcRPE1_0?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                    allowFullScreen
                  />
                </Box>
              </>
            ) : (
              <SwitchTransition>
                <CSSTransition
                  key={instructionIndex}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <Box>
                    {instructionIndex < instructions.length ? (
                      <>
                        <Box
                          component="img"
                          src={instructions[instructionIndex].image}
                          alt={`Instruction ${instructionIndex + 1} Image`}
                          sx={{
                            width: '100%',
                            maxHeight: '300px',
                            objectFit: 'contain',
                            borderRadius: '10px',
                          }}
                        />
                        <Typography id="dialog-description" sx={{ mt: 2 }}>
                          {instructions[instructionIndex].text}
                        </Typography>
                      </>
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        <iframe
                          width="100%"
                          height="250"
                          src="https://www.youtube-nocookie.com/embed/4gRGcRPE1_0?autoplay=1"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                          allowFullScreen
                        />
                      </Box>
                    )}
                  </Box>
                </CSSTransition>
              </SwitchTransition>
            )}
          </DialogContent>
          {!showAllInstructions && (
            <DialogActions>
              <Button onClick={handleBack} disabled={instructionIndex === 0}>
                Back
              </Button>
              {instructionIndex < instructions.length ? (
                <Button
                  onClick={handleNext}
                  sx={{
                    bgcolor: '#71e849',
                    color: '#fff',
                    '&:hover': { bgcolor: '#5ca338' },
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  onClick={handleFinish}
                  sx={{
                    bgcolor: '#e63946',
                    color: '#fff',
                    '&:hover': { bgcolor: '#d62839' },
                  }}
                >
                  Finish
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </Box>

      {/* Add global CSS for transitions */}
      <style>
        {`
          .fade-enter {
            opacity: 0;
          }
          .fade-enter-active {
            opacity: 1;
            transition: opacity 300ms;
          }
          .fade-exit {
            opacity: 1;
          }
          .fade-exit-active {
            opacity: 0;
            transition: opacity 300ms;
          }
        `}
      </style>
    </Container>
  );
}
