// components/AnnouncementModal.js

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

export default function AnnouncementModal({ open, onClose }) {
  // Example list of banned players. Replace or modify this array as needed.
  const bannedPlayers = [
    // Add more banned player names here
  ];

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Welcome to PLAICraft!</DialogTitle>
      <DialogContent dividers>
        {/* Who are we? */}
        <Box mb={2}>
          <Typography variant="h6">Who are we?</Typography>
          <Typography paragraph>
            We are the Pacific Laboratory for Artificial Intelligence (PLAI), a cutting-edge research
            group based in the Department of Computer Science at the University of British Columbia.
            Our mission is to advance the frontiers of artificial intelligence through groundbreaking
            work in generative modelling, reinforcement learning, and probabilistic programming.
            At our core, we are driven by a vision to ensure safe and reliable generative AI that
            humanity can trust. We aim to build systems that not only push technical boundaries but
            also make a lasting positive impact on society.
          </Typography>
        </Box>

        <Divider />

        {/* What are we researching? */}
        <Box mt={2} mb={2}>
          <Typography variant="h6">What are we researching?</Typography>
          <Typography paragraph>
            Imagine a future where you can talk to a computer program in a virtual world like
            Minecraft, and it responds and acts like a real person. That's the goal of our project:
            to create an advanced artificial intelligence (AI) that not only understands speech but
            can also respond and act within a complex virtual environment. This type of AI is known
            as embodied AI, which means it can think, act, and learn in a simulated or real-world
            setting, just like humans. By participating in our study, you are contributing your
            Minecraft behavioural data to further our research. We aim to collect thousands of hours
            of data to train and develop an advanced AI.
          </Typography>
        </Box>

        <Divider />

        {/* Changelog & Announcements */}
        <Box mt={2}>
          <Typography variant="h6">Changelog & Announcements</Typography>
          <Typography paragraph>
            We are recently experiencing a high volumes of players, which sometime exceeds the instance quota that we can provide. If you are unable to play due to this, just wait for while before joining.
          </Typography>

          {/* Banned Players */}
          <Box mt={2}>
            <Typography variant="subtitle1">Recently Banned Player Due to AFK Escaping (we can detect it easily, don't even try) : </Typography>
            {bannedPlayers.length > 0 ? (
              <List>
                {bannedPlayers.map((player, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemText primary={player} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography paragraph></Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success" // Changed to green
          onClick={onClose}
          sx={{ marginRight: 2 }}
        >
          Start Plai Now!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
