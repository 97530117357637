import * as React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import SadFaceIcon from '@mui/icons-material/SentimentDissatisfied';
import { Helmet } from 'react-helmet';
import Fade from '@mui/material/Fade';

export default function BrowserWarningForm() {
    return (
        <>
            <Helmet>
                <title>Browser Warning</title>
                <meta name="description" content="A Chromium-based browser is required to participate and play." />
            </Helmet>
            <Fade in={true} timeout={300}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        padding: { xs: 2, sm: 3 }, // Smaller padding for mobile
                        margin: '0 auto',
                        maxWidth: { xs: '100%', sm: '600px' }, // Full width on mobile, limited width on larger screens
                        textAlign: 'center',
                    }}
                >
                    <Grid container direction="column" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography 
                                variant="h4" 
                                component="h1" 
                                fontSize={{ xs: '1.5rem', sm: '2rem' }} // Responsive font size
                                lineHeight={1.3} 
                                sx={{ wordWrap: 'break-word' }}
                            >
                                Please use a Chromium-based browser on your computer to start playing
                            </Typography>
                            <Typography 
                                variant="h6" 
                                component="h2" 
                                mt={2} 
                                fontSize={{ xs: '1rem', sm: '1.25rem' }} // Responsive font size
                                sx={{ wordWrap: 'break-word' }}
                            >
                                We are super happy you signed up but we are sad to have to tell you that you have to play on a computer using a Chromium-based browser (Chrome, Edge, etc.). We hope to see you back again soon!
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SadFaceIcon sx={{ fontSize: { xs: 60, sm: 80 }, color: '#d32f2f' }} /> {/* Larger icon size on mobile */}
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </>
    );
}
